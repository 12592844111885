<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> Edit Banner</h6>
					</div>
					<div class="card-body">
						<form @submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputHeaderName" class="col-sm-2 col-form-label">Header <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="bannerRequest.headerName" @blur="validationRule()"
										class="form-control" id="inputHeaderName">
									<small class="text-danger">{{validationData.headerName}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputFooterName" class="col-sm-2 col-form-label">Footer <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="bannerRequest.footerName" @blur="validationRule()"
										class="form-control" id="inputFooterName">
									<small class="text-danger">{{validationData.footerName}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-sm-2 col-form-label"> Current Image </label>
								<div class="col-sm-10">
									<img v-if="currentImage" :src="env.mediaUrl+currentImage" class="banner-image mt-2"/>
								</div>
							</div>

							<div class="form-group row" >
								<div class="form-group row" >
									<label for="inputImage" class="col-sm-2 col-form-label">Image</label>
									<div class="col-sm-10">
										<input type="file" class="form-control" id="inputImage" @change="readImage">
										<img v-if="bannerRequest.image" v-bind:src="image" class="banner-image mt-2"/>
										<small class="text-danger">{{validationData.image}}</small>
									</div>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'categoryEdit',
		metaInfo: {
            title: "Category Edit",
            titleTemplate: "%s ← RJ Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentImage: "",
				image: null,
				filter: {
					page: "",
                    orderBy: "",
                    sortKey: "",
                    name: "",
				},
				bannerRequest: {
					id: "",
					headerName: "",
					footerName: "",
					image: ""
				},
				validationData: {
					headerName: "",
					footerName: "",
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				bannerUpdateAction: 'bannerUpdateAction',
				bannerDetailAction: 'bannerDetailAction'
			}),
			readImage(event) {
				this.bannerRequest.image = event.target.files[0];
				//For Show
				const files = event.target.files[0]
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files)
				fileReader.onload = e => {
					this.image = e.target.result
					this.validationRule()
				};
			},
			async getFields(){
				let option = {
					type: "update",
					id: this.bannerRequest.id
				}
				await this.bannerDetailAction({
					...option
				}).then(res => {
					this.currentImage = res.data.detail.image
					this.bannerRequest.headerName = res.data.detail.headerName
					this.bannerRequest.footerName = res.data.detail.footerName
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
		
				const formData = new FormData();
				formData.append('id', this.bannerRequest.id);
				formData.append('headerName', this.bannerRequest.headerName); 
				formData.append('footerName', this.bannerRequest.footerName); 
				formData.append('image', this.bannerRequest.image); 

				await this.bannerUpdateAction(
					formData
				).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'banner-list', query: { page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey, name: this.filter.name} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.bannerRequest.headerName == "") {
					this.validationData.headerName = "The Header field is required."
					isValidation.push(true)
				} else {
					this.validationData.headerName = ""
					isValidation.push(false)
				}
				if (this.bannerRequest.footerName == "") {
					this.validationData.footerName = "The Footer field is required."
					isValidation.push(true)
				} else {
					this.validationData.footerName = ""
					isValidation.push(false)
				}

				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.filter.page = this.$route.query.page
            this.filter.orderBy = this.$route.query.orderBy
            this.filter.sortKey = this.$route.query.sortKey
			this.bannerRequest.id = this.$route.query.id
			this.filter.name = this.$route.query.name
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>